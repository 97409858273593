import { gql, useLazyQuery } from "@apollo/client";
import {
  faChevronRight,
  faClose,
  faFilePdf,
  faMagnifyingGlass,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDeferredValue, useEffect, useState } from "react";
import { Link } from "react-router-dom";

type tabType = "Produkter" | "Kategorier" | "Dokument";

const GET_PRODUCTS = gql`
  query ($search: String!) {
    products(filters: { title: { contains: $search } }) {
      data {
        id
        attributes {
          title
          images {
            data {
              attributes {
                url
              }
            }
          }
          images {
            data {
              attributes {
                name
                url
              }
            }
          }
          downloads {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const GET_CATEGORIES = gql`
  query ($search: String!) {
    mainCategories(filters: { title: { contains: $search } }) {
      data {
        id
        attributes {
          title
        }
      }
    }
  }
`;

const GET_DOWNLOADS = gql`
  query ($search: String!) {
    uploadFiles(
      filters: {
        name: { contains: ".pdf" }
        and: { name: { contains: $search } }
      }
    ) {
      data {
        id
        attributes {
          name
          url
        }
      }
    }
  }
`;
interface Props {
  showSearchInput: boolean;
  setShowSearchInput: (_: boolean) => void;
}

export const SearchDropdown = ({
  showSearchInput = true,
  setShowSearchInput,
}: Props) => {
  const { REACT_APP_STRAPI_URI } = process.env;
  const [search, setSearch] = useState("");
  const tabs: tabType[] = ["Produkter", "Kategorier", "Dokument"];
  const [selectedTab, setSelectedTab] = useState<tabType>(tabs[0]);
  const debounceSearch = useDeferredValue(search);

  const [getProducts, { data }] = useLazyQuery(GET_PRODUCTS);
  const [getCategories, { data: catData }] = useLazyQuery(GET_CATEGORIES);
  const [getDownloads, { data: dlData }] = useLazyQuery(GET_DOWNLOADS);

  useEffect(() => {
    getCategories({ variables: { search: debounceSearch } });
    getProducts({ variables: { search: debounceSearch } });
    getDownloads({ variables: { search: debounceSearch } });
  }, [debounceSearch, getCategories, getProducts, getDownloads]);

  return (
    <>
      <button className="text-white hover:underline h-full w-32 cursor-default">
        <div
          className={`transition-all ${
            showSearchInput ? "w-screen bg-white" : "w-5 bg-transparent"
          } h-full flex items-center`}
        >
          {showSearchInput ? (
            <>
              <input
                autoComplete="off"
                type="text"
                name="name"
                placeholder="Sök"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                autoFocus
                className="h-full w-full md:w-52 text-slate-800 mr-5 border-transparent outline-none px-10"
                onClick={(e) => e.stopPropagation()}
              />

              <FontAwesomeIcon
                className="mr-10 text-blue-swedec cursor-pointer"
                icon={faClose}
                onClick={() => {
                  setShowSearchInput(false);
                  setSearch("");
                }}
              />
            </>
          ) : (
            <FontAwesomeIcon
              className="ml-5 text-blue-swedec cursor-pointer"
              icon={faSearch}
              onClick={() => {
                setShowSearchInput(!showSearchInput);
                setSearch("");
              }}
            />
          )}
        </div>
      </button>

      <div
        className={`w-full transition-height duration-200 z-50 ${
          search !== "" ? "h-96 py-4  border-t border-gray-100" : "h-0"
        } absolute right-0 top-16 bg-white overflow-hidden 
        flex flex-col items-center shadow-xl 
        `}
      >
        <div
          className="
             flex flex-row justify-between items-center w-full max-w-lg h-9
             border-gray-300
             text-sm
             px-4
          "
        >
          {tabs.map((tab: tabType, index: number) => (
            <div key={index}>
              <span
                onClick={() => {
                  setSelectedTab(tab);
                }}
                className={`
                  flex justify-center pb-1 cursor-pointer w-full
                  ${
                    tab === selectedTab
                      ? "border-b-4 border-blue-swedec "
                      : " text-gray-500 hover:text-gray-900 border-b-2 border-gray-300"
                  }
            `}
              >
                {tab}
                <div
                  className="
                    w-5 h-5 flex justify-center items-center ml-2
                  bg-gray-200 text-gray-500 rounded-md
                  "
                >
                  {tab === "Produkter" && (
                    <span className="text-xs">
                      {data?.products?.data?.length}
                    </span>
                  )}

                  {tab === "Kategorier" && (
                    <span className="text-xs">
                      {catData?.mainCategories?.data?.length}
                    </span>
                  )}

                  {tab === "Dokument" && (
                    <span className="text-xs">
                      {dlData?.uploadFiles?.data?.length}
                    </span>
                  )}
                </div>
              </span>
            </div>
          ))}
        </div>

        <div
          className={`${
            selectedTab === "Produkter" ? "" : "hidden"
          } flex flex-col w-full max-w-5xl mt-5 overflow-y-auto px-4`}
        >
          {data?.products?.data?.length === 0 && (
            <div className="flex flex-col items-center content-center justify-center">
              <FontAwesomeIcon
                className="text-7xl text-gray-300 font-light py-10"
                icon={faMagnifyingGlass}
              />
              <p className="text-xl text-center text-gray-00">
                Inga Produkter Hittades
              </p>
            </div>
          )}

          {data?.products?.data?.map((p: any) => (
            <Link
              key={p.id}
              to={`/product/${p.id}`}
              onClick={() => {
                setSearch("");
                setShowSearchInput(false);
              }}
              className="flex flex-row justify-between items-center cursor-pointer hover:text-blue-swedec border-b py-5"
            >
              <img
                className="bg-gray-300 w-10 h-10"
                src={`${REACT_APP_STRAPI_URI}${p.attributes.images?.data[0]?.attributes.url}`}
                alt=""
              />
              <p className="text-xl font-medium">{p.attributes.title}</p>
              <FontAwesomeIcon className="text-xl" icon={faChevronRight} />
            </Link>
          ))}
        </div>

        <div
          className={`${
            selectedTab === "Kategorier" ? "" : "hidden"
          } flex flex-col w-full max-w-5xl mt-5 overflow-y-auto px-4`}
        >
          {catData?.mainCategories?.data?.length === 0 && (
            <div className="flex flex-col items-center content-center justify-center">
              <FontAwesomeIcon
                className="text-7xl text-gray-300 font-light py-10"
                icon={faMagnifyingGlass}
              />
              <p className="text-xl text-center text-gray-00">
                Inga Kategorier Hittades
              </p>
            </div>
          )}

          {catData?.mainCategories?.data?.map((p: any) => (
            <Link
              key={p.id}
              to={`/category/${p.id}`}
              onClick={() => {
                setSearch("");
                setShowSearchInput(false);
              }}
              className="flex flex-row justify-between items-center cursor-pointer hover:text-blue-swedec border-b py-5"
            >
              <p className="text-xl font-medium">{p.attributes.title}</p>
              <FontAwesomeIcon className="text-xl" icon={faChevronRight} />
            </Link>
          ))}
        </div>

        <div
          className={`${
            selectedTab === "Dokument" ? "" : "hidden"
          } flex flex-col w-full max-w-5xl mt-5 overflow-y-auto px-4`}
        >
          {dlData?.uploadFiles?.data?.length === 0 && (
            <div className="flex flex-col items-center content-center justify-center">
              <FontAwesomeIcon
                className="text-7xl text-gray-300 font-light py-10"
                icon={faMagnifyingGlass}
              />
              <p className="text-xl text-center text-gray-00">
                Inga Dokument Hittades
              </p>
            </div>
          )}

          {dlData?.uploadFiles.data.map((p: any) => (
            <a
              key={p.id}
              target="_blank"
              rel="noopener noreferrer"
              href={`${REACT_APP_STRAPI_URI}${p.attributes.url}`}
              onClick={() => {
                setSearch("");
                setShowSearchInput(false);
              }}
              className="flex flex-row justify-between items-center cursor-pointer hover:text-blue-swedec border-b py-5"
            >
              <FontAwesomeIcon className="text-xl" icon={faFilePdf} />
              <p className="text-xl font-medium">{p.attributes.name}</p>
              <FontAwesomeIcon className="text-xl" icon={faChevronRight} />
            </a>
          ))}
        </div>
      </div>
    </>
  );
};
