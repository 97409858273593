import {gql, useQuery} from "@apollo/client";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ImageGallery from "react-image-gallery";

import {Link, useParams} from "react-router-dom";
import {RelatedProducts} from "../components/RelatedProducts";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-areas:
    "gallery title title"
    "gallery description description"
    "gallery downloads categories";

  @media (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "title title"
      "gallery gallery"
      "description description"
      "downloads categories";
  }

  @media (max-width: 639px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "gallery"
      "description"
      "downloads"
      "categories";
  }
`;

const Title = styled.h3`
  grid-area: title;
`;

const Gallery = styled.div`
  grid-area: gallery;
`;

const Description = styled.p`
  grid-area: description;
`;

const Downloads = styled.div`
  grid-area: downloads;
`;
const Categories = styled.div`
  grid-area: categories;
`;

const GET_PRODUCT = gql`
  query Product($productId: ID!) {
    product(id: $productId) {
      data {
        id
        attributes {
          title
          main_categories {
            data {
              id
              attributes {
                title
              }
            }
          }
          description
          images {
            data {
              attributes {
                name
                url
              }
            }
          }
          downloads {
            data {
              attributes {
                name
                url
              }
            }
          }
          createdAt
        }
      }
    }
  }
`;

export const ProductPage = () => {
  const { productId } = useParams();
  const { REACT_APP_STRAPI_URI } = process.env;
  const { data } = useQuery(GET_PRODUCT, {
    variables: {
      productId,
    },
  });

  if (!data) return <></>;

  const images = data?.product.data.attributes.images.data.flatMap(
    (i: any) => ({
      original: `${REACT_APP_STRAPI_URI}${i.attributes.url}`,
      thumbnail: `${REACT_APP_STRAPI_URI}${i.attributes.url}`,
    })
  );

  const defaultImages = [
    {
      original: `${REACT_APP_STRAPI_URI}/uploads/Placeholder_dfac40f40f.png`,
      thumbnail: `${REACT_APP_STRAPI_URI}/uploads/Placeholder_dfac40f40f.png`,
    },
  ];

  return (
    <div className="flex flex-col ">
      <Container className="bg-blue-swedec">
        <Gallery className="w-full overflow-hidden">
          <ImageGallery
            items={images.length > 0 ? images : defaultImages}
            showPlayButton={false}
          />
        </Gallery>

        <Title className="text-center lg:text-left text-4xl font-bold pb-5 bg-blue-swedec text-white p-10 ">
          {data?.product?.data.attributes.title}
        </Title>
        <Description
          className={`bg-blue-swedec text-white  ${
            data?.product.data.attributes.description ? "p-10" : "p-0"
          }`}
        >
          {data?.product.data.attributes.description}
        </Description>

        <Downloads className="bg-blue-swedec text-white px-10 py-5">
          <h3 className="text-2xl font-bold pb-5">Nedladdningar</h3>
          {data?.product.data.attributes.downloads.data.length > 0 ? (
            data?.product.data.attributes.downloads.data.map(
              (d: any, index: number) => (
                <a
                  key={index}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${REACT_APP_STRAPI_URI}${d.attributes.url}`}
                >
                  <span className="flex items-center mb-1 bg-lightblue-swedec rounded-lg p-2 text-slate-800 mr-5 cursor-pointer h-10">
                    <FontAwesomeIcon className="text-2xl" icon={faFilePdf} />
                    <p className="px-5">{d.attributes.name}</p>
                  </span>
                </a>
              )
            )
          ) : (
            <span className="flex items-center">
              <p>Inga nedladdningar tillgängliga</p>
            </span>
          )}
        </Downloads>

        <Categories className="bg-blue-swedec text-white px-10 py-5">
          <h3 className="text-2xl font-bold pb-5">Kategorier</h3>
          {data?.product.data.attributes.main_categories.data.map(
            (category: any, index: number) => (
              <Link
                to={`/category/${category.id}`}
                key={index}
                className="flex text-center items-center bg-lightblue-swedec rounded-lg p-2 text-slate-800 mr-5 cursor-pointer h-10"
              >
                {category.attributes.title}
              </Link>
            )
          )}
        </Categories>
      </Container>

      <div className="px-10">
        {data?.product.data.attributes.main_categories.data.map(
          (category: any) => (
            <RelatedProducts
              key={category.id}
              categoryId={category.id}
              currentProductId={productId || ""}
            />
          )
        )}
      </div>
    </div>
  );
};
