import styled from "styled-components";
import {marked} from "marked";

const Container = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  grid-template-areas:
    "img title"
    "img description";

  @media (max-width: 639px) {
    grid-template-columns: 200px auto;
  }
`;

const Image = styled.img`
  grid-area: img;
`;

const Title = styled.h3`
  grid-area: title;
`;

const Description = styled.div`
  grid-area: description;
`;

export const Blog = (props: any) => {
  const { REACT_APP_STRAPI_URI } = process.env;

  return (
    <Container className="bg-gray-100">
      <Image
        className="bg-slate-200 h-40 border-none w-full object-cover"
        src={`${REACT_APP_STRAPI_URI}${props.blog?.attributes?.image?.data?.attributes?.url}`}
        alt=""
      />
      <Title className="text-lg sm:text-xl font-bold px-5 pt-5">
        {props.blog?.attributes?.title}
      </Title>
      <Description
          dangerouslySetInnerHTML={{__html: marked.parse(props?.blog?.attributes?.body || "")}}
          className="text-sm sm:text-lg text-slate-600 px-5 pb-5" />
    </Container>
  );
};
