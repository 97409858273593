import { Link } from "react-router-dom";

interface Props {
  product: any;
}

export const ProductCard = ({ product }: Props) => {
  const { REACT_APP_STRAPI_URI } = process.env;
  const downloads = product.attributes.downloads.data;

  return (
    <Link
      to={`/product/${product.id}`}
      onClick={() => window.scrollTo(0, 0)}
      className="
            h-64
            bg-gray-100
            cursor-pointer
            flex
            flex-col
            transition-shadow
            hover:shadow-lg hover:scale-105
            active:scale-100
        "
    >
      <img
        className="h-2/3 w-full object-cover"
        src={
          product.attributes.images.data.length > 0
            ? `${REACT_APP_STRAPI_URI}${product.attributes.images.data[0]?.attributes?.url}`
            : `${REACT_APP_STRAPI_URI}/uploads/Placeholder_dfac40f40f.png`
        }
        alt=""
      />
      <div className="flex flex-col h-1/3 justify-between items-center p-2">
        <div className="flex font-bold text-sm sm:text-xl">
          {product.attributes.title}
        </div>
        <div className="flex flex-col items-center text-sm ">
          {downloads.length}{" "}
          {downloads.length === 1 ? "Nedladdning" : "Nedladdningar"}
        </div>
      </div>
    </Link>
  );
};
