import { Link } from "react-router-dom";

export const Footer = () => {
  const scrolltoTop = () => window.scrollTo(0, 0);

  return (
    <footer className="bg-gray-900 h-auto py-5 pt-10 px-5">
      <div className="w-full max-w-lg flex m-auto">
        <div className="w-1/3">
          <p className="text-white text-sm font-bold pb-2 flex items-start">
            Länkar
          </p>
          <ul className="text-gray-400 text-xs">
            <li className="pb-1">
              <Link onClick={() => scrolltoTop()} to="/">
                Hem
              </Link>
            </li>
            <li className="pb-1" onClick={() => scrolltoTop()}>
              <Link to="/omoss">Om Oss</Link>
            </li>
            <li className="pb-1" onClick={() => scrolltoTop()}>
              <Link to="/nyheter">Nyheter</Link>
            </li>
            <li className="pb-1" onClick={() => scrolltoTop()}>
              <Link to="/kontakt">Kontakt</Link>
            </li>
          </ul>
        </div>

        <div className="w-1/3">
          <p className="text-white text-sm font-bold pb-2 flex items-start">
            Kontakt
          </p>
          <ul className="text-gray-400 text-xs">
            <li className="pb-1">+46 302 236 50</li>
            <li className="pb-1">info@swedec.se</li>
            <li className="pb-1">AB Swedec Automation</li>
            <li className="pb-1">Hedeforsvägen 2</li>
            <li className="pb-1">443 61 Stenkullen</li>
            <li className="pb-1">Sverige</li>
          </ul>
        </div>

        <div className="w-1/3">
          <p className="text-white text-sm font-bold pb-2 flex items-start">
            Öppettider
          </p>
          <ul className="text-gray-400 text-xs p">
            <li className="pb-1">Mån - Fre: 07.30 - 16.00</li>
            <li className="pb-1">Lunch: 12.00 - 12.45</li>
            <li className="pb-1">Lör-Sön: Stängt</li>
          </ul>
        </div>
      </div>

      <div className="w-full flex flex-col justify-center items-center pt-10">
        <img className="w-44" src="/swedeclight.png" alt="" />
        <p className="text-gray-500 text-tiny text-center">
          Copyright © 2022 Swedec. All rights reserved
        </p>
      </div>
    </footer>
  );
};
