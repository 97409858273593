import {gql, useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import {marked} from "marked";

const GET_BLOG = gql`
  query Blog($blogId: ID!) {
    blog(id: $blogId) {
      data {
        id
        attributes {
          title
          created
          body
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const BlogPage = () => {
  const { blogId } = useParams();
  const { data } = useQuery(GET_BLOG, { variables: { blogId } });
  const { REACT_APP_STRAPI_URI } = process.env;

  if (!data?.blog?.data) return <>Blog not found</>;

  return (
    <div className="flex flex-col md:flex-row">
      {data.blog.data.attributes.image.data && (
        <img
          alt="Blog"
          className="md:h-100 w-full md:w-1/2"
          src={`${REACT_APP_STRAPI_URI}${data.blog.data.attributes.image.data.attributes.url}`}
        />
      )}

      <div className="p-10">
        <h3 className="text-4xl font-bold mb-5">
          {data.blog.data.attributes.title}
        </h3>
        <div dangerouslySetInnerHTML={{__html: marked.parse(data?.blog?.data?.attributes?.body || "")}} />
        <p className="text-sm text-right mt-5 text-gray-600">
          {data.blog.data.attributes.created}
        </p>
      </div>
    </div>
  );
};
