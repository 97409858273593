import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { ProductCard } from "../components/ProductCard";

const GET_CATEGORY = gql`
  query Category($categoryId: ID!) {
    mainCategory(id: $categoryId) {
      data {
        id
        attributes {
          title
          image {
            data {
              attributes {
                url
              }
            }
          }
          products {
            data {
              id
              attributes {
                title
                downloads {
                  data {
                    attributes {
                      url
                      name
                    }
                  }
                }
                images {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CategoryPage = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const { data } = useQuery(GET_CATEGORY, {
    variables: { categoryId },
  });

  return (
    <div className="flex">
      <div className="flex flex-col w-full">
        <h3 className="text-4xl text-slate-800 font-bold w-1/2 p-5">
          {data?.mainCategory.data.attributes.title}
        </h3>

        <div
          className="
            grid gap-4
            grid-cols-2 lg:grid-cols-3 xl:grid-cols-4
            m-5
          "
        >
          {data?.mainCategory.data.attributes.products.data.map((p: any) => (
            <ProductCard product={p} key={p.id} />
          ))}
        </div>
      </div>
    </div>
  );
};
