import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Blog } from "./Blog";

const GET_BLOGS = gql`
  query Blogs {
    blogs(pagination: { limit: 3 }) {
      data {
        id
        attributes {
          title
          body
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const Blogs = () => {
  const { loading, data, error } = useQuery(GET_BLOGS);

  if (loading) return null;
  if (!data) return null;
  if (error) return null;

  return (
    <section className="h-auto pt-6 max-w-6xl m-auto py-16">
      <p className="text-2xl sm:text-4xl font-bold text-blue-swedec m-auto pb-10 text-center">
        Nyheter
      </p>

      <div className="flex flex-wrap flex-col justify-center">
        {data.blogs?.data?.map((blog: any, index: number) => (
          <Link to={`/blogs/${blog?.id}`} className="pb-5" key={index}>
            <Blog blog={blog} />
          </Link>
        ))}
      </div>

      {data.blogs?.data?.length > 2 && (
        <Link
          to="/nyheter"
          className="bg-blue-swedec text-white p-2 flex w-100 sm:w-1/3 text-center justify-center m-auto mt-10"
        >
          Visa allt
        </Link>
      )}
    </section>
  );
};
