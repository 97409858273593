import { gql, useQuery } from "@apollo/client";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const GET_CATEGORIES = gql`
  query GetCategories {
    mainCategories(pagination: { limit: 30 }) {
      data {
        id
        attributes {
          title
          parent_category
        }
      }
    }
  }
`;

function groupItemBy(array: any, property: any) {
  var hash: any = {},
    props = property.split(".");
  for (var i = 0; i < array.length; i++) {
    var key: any = props.reduce(function (acc: any, prop: any) {
      return acc && acc[prop];
    }, array[i]);
    if (!hash[key]) hash[key] = [];
    hash[key].push(array[i]);
  }
  return hash;
}

export const ProductsDropdown = () => {
  const { loading, data } = useQuery(GET_CATEGORIES);
  const [showPanel, setShowPanel] = useState(false);
  const [categories, setCategories] = useState<any>();
  useEffect(() => {
    if (data) {
      setCategories(
        Object.values(
          groupItemBy(data.mainCategories?.data, "attributes.parent_category")
        )
      );
    }
  }, [data]);

  return (
    <>
      <Link
        to="#"
        className="cursor-pointer text-white hover:underline"
        onClick={() => setShowPanel(!showPanel)}
      >
        Produkter
        {showPanel ? (
          <FontAwesomeIcon className="pl-3" icon={faChevronUp} />
        ) : (
          <FontAwesomeIcon className="pl-3" icon={faChevronDown} />
        )}
      </Link>

      <div
        className={`w-full transition-height duration-200 ${
          showPanel ? "h-80 z-30" : "h-0"
        } absolute left-0 top-32 bg-white overflow-hidden
        flex justify-center
        shadow-lg
        `}
      >
        {loading ? (
          <>loading...</>
        ) : (
          <div className="flex w-full justify-center max-w-2xl">
            {categories?.map((d: any, index: number) => (
              <div className="w-1/3 flex flex-col items-center" key={index}>
                <p className="text-blue-swedec font-bold text-lg py-5 text-left">
                  {d[0].attributes.parent_category}
                </p>

                <ul>
                  {d.map((d: any, index: number) => (
                    <Link
                      key={index}
                      onClick={() => {
                        setShowPanel(false);
                      }}
                      to={`/category/${d.id}`}
                    >
                      <li className="transition-colors p-1.5 font-medium hover:bg-lightblue-swedec rounded-lg cursor-pointer text-center">
                        {d.attributes.title}
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
