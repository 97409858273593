import { ContactCard } from "../components/ContactCard";
import { gql, useQuery } from "@apollo/client";

const GET_ABOUT_US_PAGE = gql`
  query AboutUsPage {
    aboutUsPage {
      data {
        attributes {
          first {
            title
            card {
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
              name
              role
              telephone
              mobile
              email
            }
          }
          second {
            title
            description {
              paragraph
            }
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
          third {
            title
            description {
              paragraph
            }
          }
          fourth {
            title
            description {
              paragraph
            }
          }
        }
      }
    }
  }
`;

export const AboutPage = () => {
  const { REACT_APP_STRAPI_URI } = process.env;
  const { data } = useQuery(GET_ABOUT_US_PAGE);

  if (!data) return null;

  return (
    <div>
      <div>
        <h3 className="text-2xl sm:text-4xl font-bold pl-8 pt-8 text-gray-700">
          {data.aboutUsPage?.data?.attributes?.first?.title}
        </h3>

        <div className="flex flex-col sm:flex-row justify-center my-10">
          {data.aboutUsPage?.data?.attributes?.first?.card?.map(
            (card: any, index: number) => (
              <ContactCard
                key={index}
                name={card?.name}
                role={card?.role}
                picture={
                  REACT_APP_STRAPI_URI + card?.image?.data?.attributes?.url
                }
                mobile={card?.mobile}
                telephone={card?.telephone}
                email={card?.email}
              />
            )
          )}
        </div>

        <div className="bg-blue-swedec flex flex-col md:flex-row overflow-hidden text-white">
          <div className="w-100 md:w-1/2 p-8">
            <h3 className="text-2xl sm:text-4xl font-bold pb-5">
              {data.aboutUsPage?.data?.attributes?.second?.title}
            </h3>

            {data.aboutUsPage?.data?.attributes?.second?.description?.map(
              ({ paragraph }: any, index: number) => (
                <p key={index} className="py-3">
                  {paragraph}
                </p>
              )
            )}
          </div>

          <img
            src={
              REACT_APP_STRAPI_URI +
              data.aboutUsPage?.data?.attributes?.second?.image?.data
                ?.attributes?.url
            }
            className="w-100 md:w-1/2 h-100"
            alt=""
          />
        </div>

        <div className="bg-lightblue-swedec flex flex-col md:flex-row overflow-hidden p-5 text-gray-700">
          <div className="w-100 md:w-1/2 p-3">
            <h3 className="text-2xl sm:text-4xl font-bold pb-5">
              {data.aboutUsPage?.data?.attributes?.third?.title}
            </h3>

            {data.aboutUsPage?.data?.attributes?.third?.description?.map(
              ({ paragraph }: any, index: number) => (
                <p key={index} className="py-3">
                  {paragraph}
                </p>
              )
            )}
          </div>

          <div className="w-100 md:w-1/2 p-3">
            <h3 className="text-2xl sm:text-4xl font-bold pb-5">
              {data.aboutUsPage?.data?.attributes?.fourth?.title}
            </h3>

            {data.aboutUsPage?.data?.attributes?.fourth?.description?.map(
              ({ paragraph }: any, index: number) => (
                <p key={index} className="py-3">
                  {paragraph}
                </p>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
