interface Props {
  name: string;
  role: string;
  picture: string;
  telephone: string;
  mobile: string;
  email: string;
}

export const ContactCard = ({
  name,
  role,
  picture,
  telephone,
  mobile,
  email,
}: Props) => {
  return (
    <div
      className="
        mx-5
        my-5 sm:my-0
        w-100 sm:w-52
        shadow-lg flex flex-col justify-center items-center rounded-md
        transition-transform
        hover:scale-105
      "
      style={{
        backgroundImage:
          "linear-gradient(to bottom, #006DBD 28%, #F2F2F2 28% )",
      }}
    >
      <img className="w-36 h-36 mt-5" src={picture} alt="" />
      <p className="font-bold py-3">{name}</p>
      <span className="bg-lightblue-swedec px-5 py-1 rounded-full text-sm">
        {role}
      </span>
      <div className="text-gray-600 text-sm p-4 text-center">
        <p>Tel: {telephone}</p>
        <p>Mobile: {mobile}</p>
        <p>{email}</p>
      </div>
    </div>
  );
};
