import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

const GET_CAROUSELS = gql`
  query Carousels {
    carousels {
      data {
        attributes {
          title
          description
          image {
            data {
              attributes {
                url
              }
            }
          }
          product {
            data {
              id
              attributes {
                main_categories {
                  data {
                    id
                  }
                }
              }
            }
          }
          main_category {
            data {
              id
            }
          }
        }
      }
    }
  }
`;
export const Carousel = () => {
  const { REACT_APP_STRAPI_URI } = process.env;

  const [index, setIndex] = useState<number>(0);
  const { loading, data, error } = useQuery(GET_CAROUSELS);

  useEffect(() => {
    const interval = setInterval(() => {
      index >= data.carousels.data?.length - 1
        ? setIndex(0)
        : setIndex(index + 1);
    }, 5000);

    return () => clearInterval(interval);
  }, [index, data?.carousels.data?.length]);

  if (loading) return null;
  if (!data) return null;
  if (error) return null;
  if (data.carousels.data.length === 0) return null;

  return (
    <>
      <section className="bg-blue-swedec h-80 flex hover:underline decoration-white cursor-pointer">
        <Link
          to={
            data.carousels.data[index].attributes.product.data
              ? `/product/${data.carousels.data[index].attributes.product.data.id}`
              : `/category/${data.carousels.data[index].attributes.main_category.data.id}`
          }
          className="flex w-full"
        >
          <div className="w-1/2 overflow-hidden">
            <img
              src={`${REACT_APP_STRAPI_URI}${data.carousels.data[index].attributes.image.data.attributes.url}`}
              className="object-cover w-full h-full"
              alt=""
            />
          </div>
          <div className="flex flex-col justify-center p-5 w-1/2 min-w-100">
            <p className="text-4xl sm:text-6xl text-white pb-5">
              {data.carousels.data[index].attributes.title}
            </p>
            <p className="text-2xl sm:text-4xl text-white">
              {data.carousels.data[index].attributes.description}
            </p>
          </div>
        </Link>

        <div className="relative ">
          <span className="flex absolute right-5 bottom-5">
            {data.carousels.data.map((t: any, i: number) => (
              <div
                key={i}
                className={`cursor-pointer mr-1 w-5 h-5 border-2 border-white rounded-full ${
                  index === i && "bg-white"
                } `}
                onClick={() => {
                  setIndex(i);
                }}
              />
            ))}
          </span>
        </div>
      </section>
    </>
  );
};
