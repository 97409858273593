import { gql, useQuery } from "@apollo/client";
import { ProductCard } from "./ProductCard";

interface Props {
  categoryId: string;
  currentProductId: string;
}

const GET_CATEGORY = gql`
  query Category($categoryId: ID!) {
    mainCategory(id: $categoryId) {
      data {
        id
        attributes {
          title
          image {
            data {
              attributes {
                url
              }
            }
          }
          products {
            data {
              id
              attributes {
                title
                downloads {
                  data {
                    attributes {
                      url
                      name
                    }
                  }
                }
                images {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const RelatedProducts = ({ categoryId, currentProductId }: Props) => {
  const { data } = useQuery(GET_CATEGORY, {
    variables: { categoryId },
  });

  const relatedCategories =
    data?.mainCategory.data.attributes.products.data.filter(
      (p: any) => p.id !== currentProductId
    );

  return (
    <div className="h-auto w-full flex flex-col pb-10">
      <p className="text-2xl sm:text-4xl font-bold py-10">
        {relatedCategories?.length === 0
          ? "Inga Relaterade produkter"
          : "Relaterade produkter"}
      </p>
      <div
        className="
            grid gap-5
            grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5
        "
      >
        {relatedCategories?.map(
          (p: any) =>
            p.id !== currentProductId && <ProductCard product={p} key={p.id} />
        )}
      </div>
    </div>
  );
};
