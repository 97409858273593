import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ProductsDropdown } from "./ProductsDropdown";
import { SearchDropdown } from "./SearchDropdown";

export const Header = () => {
  const [showSearchInput, setShowSearchInput] = useState(false);

  return (
    <header className="h-32 min-w-full bg-blue-swedec flex flex-col justify-center items-center overflow-hidden">
      <div className="flex flex-row justify-between bg-white h-32 w-full">
        <SearchDropdown
          showSearchInput={showSearchInput}
          setShowSearchInput={setShowSearchInput}
        />

        <Link
          to="/"
          className={`bg-white text-white p-4 ${
            showSearchInput && "hidden md:flex"
          }`}
        >
          <img className="h-8" src="/logo.png" alt="" />
        </Link>

        <Link
          to="/kontakt"
          className={`bg-darkblue-swedec text-white p-4 h-full flex items-center w-32 ${
            showSearchInput && "hidden md:flex"
          }`}
        >
          <FontAwesomeIcon className="text-sm pr-3" icon={faPaperPlane} />
          <span className="font-medium">Kontakt</span>
        </Link>
      </div>

      <div className="flex w-full justify-center h-full items-center">
        <div className="w-72 flex justify-between ml-5">
          <ProductsDropdown />
          <Link className="text-white hover:underline" to="/nyheter">
            Nyheter
          </Link>
          <Link className="text-white hover:underline" to="/omoss">
            Om Oss
          </Link>
        </div>
      </div>
    </header>
  );
};
