import {Blogs} from "../components/Blogs";
import {Carousel} from "../components/Carousel";

export const LandingPage = () => {
  return (
      <div>
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative m-5" role="alert">
              <strong className="font-bold">Important! </strong>
              <span className="block sm:inline">
            We are currently having technical issues with our email. If you wish to contact us then please call us on +46 302 236 50
          </span>
          </div>
          <Carousel/>
          <section className="bg-lightblue-swedec h-96 flex justify-center items-center overflow-hidden">
              <p className="text-2xl sm:text-4xl text-slate-800 font-bold w-1/2 p-5">
                  Din leverantör av komponenter för automationsteknik samt reglering av
                  flytande och gasformiga media
              </p>
              <img src="/Bitmap.png" className="w-1/2" alt="Swedec office"/>
          </section>
          {/*<section className="flex justify-center items-center overflow-hidden">*/}
          {/*    <img src="/merry-christmas-2023.png" className="w-full" alt="Christmas holiday" />*/}
          {/*</section>*/}
          <Blogs/>
      </div>
  );
};
